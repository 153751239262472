<template>
  <div id="searchVue" class="flex-shrink-0 flex-grow-1 p-md-6 py-sm-3 px-sm-0">

    <div class="form-group row">
      <div class="col-2">
      </div>
      <div class="col-8">
        Here are the households that may match your information. Please select the correct household. 
        If you can only find a previous address, please select that one and submit a Change of Address 
        to update our records. Note that by selecting a household, you are confirming that this is, or 
        was, your legal address.
      </div>
      <div class="col-2">
      </div>
    </div>

    <div class="mw-1200 mx-auto">
      <div class="card mb-5">
        <div class="card-body">
          <form id="searchForm" class="text-left" autocomplete="off">
            <div class="row">

              <div class="col-sm-3">
                <div class="form-group mb-0">
                  <label for="firstName" class="sr-only">First Name</label>
                  <input 
                    type="text" 
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    v-model="$v.form.firstName.$model"
                    maxlength="64"
                    class="form-control"
                    :class="{
                      'is-valid': !$v.form.firstName.$error && $v.form.firstName.$dirty,
                      'is-invalid': $v.form.firstName.$error
                    }" 
                    :disabled="pcHasHousehold()">
                </div>
              </div>
              
              <div class="col-sm-3">
                <div class="form-group mb-0">
                  <label for="lastName" class="sr-only">Last Name</label>
                  <input 
                    type="text" 
                    id="lastName" 
                    name="lastName"
                    placeholder="Last Name"
                    v-model="$v.form.lastName.$model"
                    maxlength="64"
                    class="form-control"
                    :class="{
                      'is-valid': !$v.form.lastName.$error && $v.form.lastName.$dirty,
                      'is-invalid': $v.form.lastName.$error
                    }" 
                    :disabled="pcHasHousehold()">
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group mb-0">
                  <label for="zip5" class="sr-only">Zip Code</label>
                  <input 
                    type="text" 
                    id="zip5" 
                    name="zip5"
                    placeholder="Zip Code"
                    v-model="$v.form.zip5.$model"
                    maxlength="5"
                    class="form-control"
                    :class="{
                      'is-valid': !$v.form.zip5.$error && $v.form.zip5.$dirty,
                      'is-invalid': $v.form.zip5.$error
                    }" 
                    :disabled="pcHasHousehold()">
                </div>
              </div>

              <div class="col-sm-3">
                  <button type="button" 
                          class="btn btn-block btn-primary position-relative vert-center" 
                          :disabled="pcHasHousehold() || $v.form.$invalid" 
                          @click="searchForHouseholds()">Search</button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="search-results position-relative">
      <div class="list-group">

        <div v-if="results != null && !results.length" class="list-group-item d-flex flex-row p-3">
          <p class="text-gray mb-0">0 Results. We have no record of you.</p>
        </div>

        <div v-else v-for="result in results" :key="result.householdId + '-' + result.individualId" class="list-group-item d-flex flex-row p-3">
          <div class="flex-grow-1">
            <span v-if="isSelectedHousehold(result.householdId)" style="background-color: yellow; font-weight: bold">
              &nbsp;&nbsp;*** Selected ***&nbsp;&nbsp;<br>
            </span>
            <strong>
              {{ result.firstName }} {{ result.lastName }}
            </strong>
            <address class="mb-0">
              {{ result.address }}<br>
              {{ result.city }}, {{ result.state }} {{ result.zip5 }}
            </address>
          </div>
          <div class="flex-grow-0">
            <button type="button" class="btn btn-primary position-relative vert-center" :disabled="pcHasHousehold()" @click="selectHousehold(result)">Select</button>
          </div>
        </div>
      </div>
      <br><br>
    </div>

    <div class="form-group row">
      <div class="col-2">
      </div>
      <div class="col-8">
        If you cannot find your household within your current or any former zip code,<br>
        please enter your full address below:<br><br>
        <textarea 
          id="coa" 
          v-model="form.coa" 
          maxlength="1024"
          rows="2" 
          placeholder="Address, City, State Zip" 
          class="form-control"
        />
        <button type="button" class="btn btn-secondary position-relative vert-center" @click="saveCoa()">Save</button>
      </div>
      <br>
        <div class="col-2">
      </div>
    </div>
  </div>

</template>

<script>
  import { pcMixin } from '@/mixins/pcMixin.js'
  import { apiMixin } from '@/mixins/apiMixin.js';

  import { helpers, required, minLength } from 'vuelidate/lib/validators';

  // Zip codes must be exactly 5 digits.
  const zip5rule = helpers.regex('digit', /^(\d{5})?$/);

  export default {
    name: "SearchView",

    mixins: [ pcMixin, apiMixin],

    data() {
      return {
        // The Store.
        sem: this.$store.getters.sem,
        pc: this.$store.getters.pc,

        form: {
          firstName: this.$store.getters.pc.firstName,
          lastName: this.$store.getters.pc.lastName,
          zip5: this.$store.getters.pc.zip5,
          coa: this.$store.getters.pc.coa
        },

        // An array to hold the results of the query to find matching households.
        results: [
        ]
      }
    },

    validations: {
      form: {
        firstName: { required },
        lastName: { required },
        zip5: { required, zip5rule },
        coa: { minLength: minLength(1) }
      }
    },

    methods: {
      selectHousehold(result) {
        this.pcModalConfirm("By selecting this household, you are confirming that this is your legal residence.")
        .then((value) => {
          if (value) {
            this.pcSpinnerShow();

            this.apiSelectHousehold(result.zip5, result.householdId, result.individualId)
            .then((response => {
              this.parseAccountPc(response.data);
              this.$router.push({ name: 'PersonalInformationRoute' })            
            }))
            .catch(() => {
              this.pcModal("Your session has expired. Please log back in.")
              .then(() => {
                this.$router.push({ name: 'SignInRoute' })
              })
              .catch(() => {})
            })
            .finally(() => {
              this.pcSpinnerHide();
            })
          }
        })
        .catch(() => {})
      },

      saveCoa() {
        this.pcSpinnerShow();
        setTimeout(() => {
          this.pcSpinnerHide();

          this.pc.coa = this.form.coa;

          this.pcModal("Your changes have been saved")
          .then(() => {
            this.apiSaveCoa()
            .then(() => {})
            .catch((error) => {
              this.handleError(error)
            })
            .finally(() => {})
          })
          .finally(() => {})
        }, 2000);
      },
      
      isSelectedHousehold(hhId) {
        return hhId && hhId == this.pc.householdId
      },

      parseAccountPc(data) {
        for (let key of Object.keys(data)) {
          this.pc[key] = data[key];
        }
      },

      searchEnabled() {
        return !this.pcHasHousehold()
      },

      searchForHouseholds() {
        this.pcSpinnerShow();
        this.apiLookupHouseholds(this.pc.email, this.form.firstName, this.form.lastName, this.form.zip5)
        .then((response) => { this.results = response.data })
        .catch((err) => { this.handleError(err) })
        .finally(() => { this.pcSpinnerHide() });
      }
    },

    mounted() {
      this.searchForHouseholds()
    }
  }
</script>
